<style lang="scss"></style>

<template>
  <b-modal :id="modalName" title="Update list" hide-footer>
    <div class="update-list-modal">
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(addList)">
          <section class="mb-2">
            <div>
              <label>Name</label>
              <validation-provider
                v-slot="validationContext"
                name="Name"
                :rules="{ required: true, min: 1 }"
              >
                <b-form-input
                  v-model="list.name"
                  :state="getValidationState(validationContext)" class="mb-1" placeholder="Enter name*"
                />

                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </div>

            <div>
              <label>Domains</label>
              <validation-provider
                v-slot="validationContext"
                name="Domains"
                :rules="{ proxyExclude: true }"
              >
                <b-form-textarea
                  v-model="list.domains"
                  :state="getValidationState(validationContext)" size="lg" class="mb-1" placeholder="Enter comma separated domain list"
                />

                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </div>

          </section>

          <section class="d-inline-flex full-width mt-2">
            <div class="ui-spacer" />
            <b-button class="mr-2" @click="close">Cancel</b-button>
            <b-button type="submit" variant="primary">Update List</b-button>
          </section>
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import GlobalProxyExcludeListsService from "@/services/GlobalProxyExcludeListsService";

export default {
  components: {},
  data() {
    return {
      modalName: 'modal-update-global-list',
      list: {
        id: '',
        name: '',
        domains: '',
      },
    }
  },
  methods: {
    show(list) {
      Object.assign(this.list, list)
      this.$bvModal.show(this.modalName)
    },
    close() {
      this.$bvModal.hide(this.modalName)
    },
    addList() {
      GlobalProxyExcludeListsService.updateList(this.list.id, this.list)
        .then(() => {
          this.$toast.success(`Updated list ${this.list.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          })
          this.$emit('refresh', true)
          this.close();
        })
        .catch(err => {
          const res = err.response
          let errorText = 'Could not update list, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        })
    },
    getValidationState({
      dirty,
      validated,
      valid = null,
    }) {
      return dirty || validated ? valid : null
    },
  },
}
</script>
