<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div>
    <b-card no-body class="mb-0">
      <b-card-title class="ml-2 mt-2 mb-0">Global Proxy Exclude Lists</b-card-title>
      <b-card-body class="d-inline mt-0">This page shows the global proxy exclude lists that can be added onto the proxy exclude lists within the enterprises.</b-card-body>

      <b-button @click="$refs.addListModal.show()" class="d-flex ml-auto btn-success mr-2">
        Create New List
      </b-button>
      <div class="mb-1 d-flex align-items-baseline">
        <div class="ui-spacer"></div>
      </div>

      <section class="">
        <b-table
          class="data-table"
          hover
          :items="proxyExcludeLists"
          :fields="headers"
          :responsive="true"
        >

          <template #cell(domains)="data">
            {{ data.item.domains === '' ? 0 : data.item.domains.split('\n').length }}
          </template>

          <template #cell(actions)="data">
            <b-dropdown class="m-0" variant="link" right toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>

              <b-dropdown-item-button class="full-nested-btn" @click="$refs.updateListModal.show(data.item)">
                Update List
              </b-dropdown-item-button>

              <b-dropdown-item-button class="full-nested-btn" @click="deleteList(data.item)">
                Delete List
              </b-dropdown-item-button>
            </b-dropdown>
          </template>
        </b-table>
        <b-row v-if="proxyExcludeLists.length === 0">
          <b-col class="ml-2 mt-1">
            <p>No results</p>
          </b-col>
        </b-row>
      </section>

      <add-global-list-modal ref="addListModal" :uid="uid" @refresh="getLists" />
      <update-global-list-modal ref="updateListModal" :uid="uid" @refresh="getLists" />


    </b-card>
  </div>

</template>
<!--eslint-enable-->

<script>
import 'vue-search-select/dist/VueSearchSelect.css';
import Vue from "vue";
import confirmBox from "@/views/parts/parts";
import GlobalProxyExcludeListsService from "@/services/GlobalProxyExcludeListsService";
import updateGlobalListModal from "@/views/globalProxyExcludeLists/sections/UpdateGlobalListModal.vue";
import addGlobalListModal from "@/views/globalProxyExcludeLists/sections/AddGlobalListModal.vue";

export default {
  name: 'EapiPolicies',
  components: {
    addGlobalListModal, updateGlobalListModal,
  },
  data() {
    return {
      selectedList: null,
      proxyExcludeLists: [],
      headers: [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'domains',
          label: 'Domains',
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right',
        },
      ],
    }
  },
  mounted() {
    this.getLists()
  },
  methods: {
    async getLists() {
      try {
        const listRequest = await GlobalProxyExcludeListsService.getGlobalProxyExcludeLists();
        this.proxyExcludeLists = listRequest.data;
      } catch (e) {
        Vue.$toast.error('Could not get proxy excluded lists, please refresh and try again', {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      }
    },
    async deleteList(list) {
      try {
        const result = await this.$bvModal.msgBoxConfirm(`Are you sure you wish to delete the list "${list.name}"`, confirmBox);

        if (result === true) {
          await GlobalProxyExcludeListsService.deleteList(list.id);
          this.$toast.success(`Deleted list ${list.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          })
          await this.getLists()
        }
      } catch (err) {
        const res = err.response
        let errorText = 'Could not delete list, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
