import axios from '@axios';
import HelperService from '@/services/HelperService';

const baseUrl = '/admin/proxy-exclude';

export default {
  getGlobalProxyExcludeLists() {
    return axios.get(`${baseUrl}`);
  },
  getSlimGlobalProxyExcludeLists() {
    return axios.get(`${baseUrl}/slim`);
  },
  createGlobalProxyExcludeList(proxyExcludeListCreate) {
    return axios.post(`${baseUrl}`, proxyExcludeListCreate);
  },
  deleteList(listUid) {
    return axios.delete(`${baseUrl}/${listUid}`);
  },
  updateList(listId, list) {
    return axios.put(`${baseUrl}/${listId}`, list);
  },
};
